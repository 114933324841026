import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {ViewsModule} from './views/views.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpClientModule} from '@angular/common/http';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {LayoutContainersModule} from './containers/layout/layout.containers.module';
import {RestApiService, createRieskoRestApiService} from './shared/rest-api.service';
import {RieskoAuthGuardService} from './shared/riesko-auth-guard.service';
import {StorageService} from './shared/storage.service';
import {Router} from '@angular/router';
import {RieskoExceptionHandler} from './shared/riesko-exception.handler';
import {ErrorHandler} from '@angular/core';
import {MessagesService} from './containers/layout/rightbar/messages.service';
import {NotificationsService, SimpleNotificationsModule} from 'angular2-notifications';
import {TokensService} from './shared/tokens.service';
import {HeadingService} from './shared/heading.service';
import {BiaRestApiService, createBiaRestApiService} from './shared/bia-rest-api.service';
import {RieskoErrorNotificationService} from './shared/riesko-error-notification.service';
// import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {QuillModule} from 'ngx-quill';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';
import {createUserRestApiService, UserRestApiService} from './shared/user-rest-api.service';
import {from} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {Translations} from './services/translations.service';
import {TipoBiaService} from './services/tipo-bia.service';
import {TimeInterspersedService} from './services/time-interspersed.service';
import {AllRestApiService} from './shared/all-rest-api.service';
import {InspectorService} from './services/inspector.service';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {DndModule} from 'ngx-drag-drop';

// let BsLocaleService;

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../lang/structured/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    DndModule,
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    SimpleNotificationsModule.forRoot(),
    QuillModule.forRoot(),
    NgxCurrencyModule.forRoot({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: false,
      min: null,
      max: null,
      inputMode: CurrencyMaskInputMode.NATURAL
    }),
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    Translations,
    BsLocaleService,
    TimeInterspersedService,
    {provide: HeadingService, useClass: HeadingService},
    {provide: StorageService, useClass: StorageService, deps: [Router]},
    {provide: TokensService, useClass: TokensService, deps: [StorageService]},
    {provide: RestApiService, useFactory: createRieskoRestApiService, deps: [TranslateService, StorageService, Router, TokensService, RieskoErrorNotificationService]},
    {provide: BiaRestApiService, useFactory: createBiaRestApiService, deps: [TranslateService, StorageService, Router, TokensService]},
    {provide: UserRestApiService, useFactory: createUserRestApiService, deps: [TranslateService, StorageService, Router, TokensService, RieskoErrorNotificationService]},
    {provide: AllRestApiService, useClass: AllRestApiService, deps: [TranslateService, StorageService, Router, TokensService, RieskoErrorNotificationService]},
    {provide: RieskoAuthGuardService, useClass: RieskoAuthGuardService},
    {provide: ErrorHandler, useClass: RieskoExceptionHandler, deps: [NotificationsService]},
    {provide: MessagesService, useClass: MessagesService, deps: [RestApiService]},
    {provide: RieskoErrorNotificationService, useClass: RieskoErrorNotificationService},
    {provide: TipoBiaService, useClass: TipoBiaService, deps: [TranslateService, Translations]},
    {provide: InspectorService, useClass: InspectorService, deps: [BsModalService]},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // iniettatore di servizi. Utilizzabile fuori dai costruttori dei componenti
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }


}

