import {BasePath, BaseService, Body, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {GenericResponse} from '../../data/riesko/generic-response';
import {AdminActionsResponse} from '../../data/riesko/responses/admin-actions-response';
import {AdminBudgetResponse, BudgetItemResponse} from '../../data/riesko/responses/budget-response';
import {ACCEPT_JSON} from '../base-rest-api';
import {AdminModule, AdminNode, AdminNodeDetail, AdminNodeRole, AdminPolicy, AdminRole, AdminRoleDetail, AdminRoleUserEnroll, AdminUser} from '../../data/riesko/responses/admin-node';
import {environment} from '../../../environments/environment';
import {AddModuleDataSet, AddPolicyData, AddPolicyDataSet, AdminLookup, AdminLookupData, AdminLookupTable} from '../../data/riesko/responses/admin-lookup';

@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class AdminApiService extends BaseService {
  @GET('/')
  async ping(): Promise<Response> {
    return {} as Response<any>;
  }

  // ==================================================================================
  //    ADMIN
  // ==================================================================================
  @GET('/admin')
  async getAdminActions(): Promise<Response<GenericResponse<AdminActionsResponse>>> {
    return {} as Response<GenericResponse<AdminActionsResponse>>;
  }

  @GET('/admin/budget/{nodeCode}')
  async getBudgets(@Path('nodeCode') nodeCode: string): Promise<Response<GenericResponse<AdminBudgetResponse>>> {
    return {} as Response;
  }

  @POST('/admin/budget/{nodeCode}/create')
  async newBudget(@Path('nodeCode') nodeCode: string): Promise<Response<GenericResponse<AdminBudgetResponse>>> {
    return {} as Response;
  }

  @POST('/admin/budget/{nodeCode}/inherit')
  async inheritBudgetNode(@Path('nodeCode') nodeCode: string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  @POST('/admin/budget/{nodeCode}/{id}/delete')
  async deleteBudget(@Path('nodeCode') nodeCode: string, @Path('id') id: string | number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/budget/{nodeCode}/{id}/activate')
  async activateBudget(@Path('nodeCode') nodeCode: string, @Path('id') id: string | number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }


  @GET('/admin/budget/{nodeCode}/{id}')
  async openBudget(@Path('nodeCode') nodeCode: string, @Path('id') id: string | number): Promise<Response<GenericResponse<BudgetItemResponse>>> {
    return {} as Response;
  }

  @GET('/admin/budget/{nodeCode}/{id}/edit')
  async editBudget(@Path('nodeCode') nodeCode: string, @Path('id') id: string | number): Promise<Response<GenericResponse<BudgetItemResponse>>> {
    return {} as Response;
  }

  @POST('/admin/budget/{nodeCode}/{id}')
  async saveBudget(@Path('nodeCode') nodeCode: string, @Path('id') id: string | number, @Body budget: BudgetItemResponse): Promise<Response<GenericResponse<BudgetItemResponse>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    ADMIN LOOKUP
  // ==================================================================================

  @GET('/admin/lookup')
  @Headers(ACCEPT_JSON)
  async getAdminLookups(): Promise<Response<GenericResponse<AdminLookup[]>>> {
    return {} as Response;
  }

  @GET('/admin/lookup/{classname}')
  @Headers(ACCEPT_JSON)
  async getAdminLookupTable(@Path('classname') lookupClass: string): Promise<Response<GenericResponse<AdminLookupTable>>> {
    return {} as Response;
  }

  @POST('/admin/lookup/{classname}/{id}/drop')
  @Headers(ACCEPT_JSON)
  async dropAdminLookupData(@Path('classname') lookupClass: string, @Path('id') idOrCode: any): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  @POST('/admin/lookup/{classname}/create')
  @Headers(ACCEPT_JSON)
  async createAdminLookupData(@Path('classname') lookupClass: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/lookup/{classname}/{id}')
  @Headers(ACCEPT_JSON)
  async saveAdminLookup(@Path('classname') lookupClass: string,
                        @Path('id') id: any,
                        @Body data: AdminLookupData): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    ADMIN ROLES
  // ==================================================================================

  @GET('/admin/roles/policies')
  @Headers(ACCEPT_JSON)
  async getAdminRolePolicies(): Promise<Response<GenericResponse<AdminPolicy[]>>> {
    return {} as Response;
  }

  @GET('/admin/roles/modules')
  @Headers(ACCEPT_JSON)
  async getAdminRoleModules(): Promise<Response<GenericResponse<AdminModule[]>>> {
    return {} as Response;
  }

  @GET('/admin/roles')
  @Headers(ACCEPT_JSON)
  async getAdminRoles(): Promise<Response<GenericResponse<AdminRole[]>>> {
    return {} as Response;
  }

  @GET('/admin/roles/{id}')
  @Headers(ACCEPT_JSON)
  async getAdminRoleDetails(@Path('id') id: string | number): Promise<Response<GenericResponse<AdminRoleDetail>>> {
    return {} as Response;
  }

  @POST('/admin/roles/create/{code}')
  @Headers(ACCEPT_JSON)
  async addRole(@Path('code') id: string | number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/roles/{id}/policy-add')
  @Headers(ACCEPT_JSON)
  async addPolicyToRole(@Path('id') id: string | number, @Body policies: AddPolicyDataSet): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/roles/{id}/policy-drop')
  @Headers(ACCEPT_JSON)
  async dropPolicyToRole(@Path('id') id: string | number, @Body policies: { policies: string[] }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/roles/{id}/module-add')
  @Headers(ACCEPT_JSON)
  async addModuleToRole(@Path('id') id: string | number, @Body modules: AddModuleDataSet): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/roles/{id}/module-drop')
  @Headers(ACCEPT_JSON)
  async dropModuleToRole(@Path('id') id: string | number, @Body policies: AddModuleDataSet): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    ADMIN NODES
  // ==================================================================================

  @GET('/admin/nodes')
  @Headers(ACCEPT_JSON)
  async getAdminNodes(): Promise<Response<GenericResponse<AdminNode[]>>> {
    return {} as Response;
  }

  @GET('/admin/nodes/{id}')
  @Headers(ACCEPT_JSON)
  async getAdminNodeDetails(@Path('id') id: string | number): Promise<Response<GenericResponse<AdminNodeDetail>>> {
    return {} as Response;
  }

  @GET('/admin/users')
  @Headers(ACCEPT_JSON)
  async getAdminNodeUsers(): Promise<Response<GenericResponse<AdminUser[]>>> {
    return {} as Response;
  }

  // Ottengo ruoli del nodo
  @GET('/admin/nodes/{id}/roles')
  @Headers(ACCEPT_JSON)
  async getAdminNodesRoles(@Path('id') id: string | number): Promise<Response<GenericResponse<{ role: AdminNodeRole }[]>>> {
    return {} as Response;
  }

  @GET('/admin/nodes/{id}/enrolls')
  @Headers(ACCEPT_JSON)
  async getAdminNodeEnrolls(@Path('id') id: string | number): Promise<Response<GenericResponse<AdminRoleUserEnroll[]>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}/role-enroll/{roleId}')
  @Headers(ACCEPT_JSON)
  async saveAdminNodeEnrolls(@Path('id') id: string | number,
                             @Path('roleId') roleId: string,
                             @Body usersIds: Partial<AdminRoleUserEnroll>): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}/role-unenroll/{roleId}')
  @Headers(ACCEPT_JSON)
  async deleteAdminNodeEnrolls(@Path('id') id: string | number,
                               @Path('roleId') roleId: string,
                               @Body usersIds: Partial<AdminRoleUserEnroll>): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // deleteAdminNodeRole
  @POST('/admin/nodes/{id}/roles/{roleId}/drop')
  @Headers(ACCEPT_JSON)
  async deleteAdminNodeRole(@Path('id') id: string | number, @Path('roleId') roleId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}/drop')
  @Headers(ACCEPT_JSON)
  async deleteAdminNode(@Path('id') id: string | number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}/append/{newCode}')
  @Headers(ACCEPT_JSON)
  async appendAdminNode(@Path('id') id: string | number, @Path('newCode') newCode: string): Promise<Response<GenericResponse<AdminNodeDetail>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}/rename/{newCode}')
  @Headers(ACCEPT_JSON)
  async renameAdminNode(@Path('id') id: string | number, @Path('newCode') newCode: string): Promise<Response<GenericResponse<AdminNodeDetail>>> {
    return {} as Response;
  }

  @POST('/admin/nodes/{id}')
  @Headers(ACCEPT_JSON)
  async saveAdminNodeDetail(@Path('id') id: string | number, @Body node: AdminNodeDetail): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // clear TODO: sintassi inventata da me, da verificare con [Witto]
  @POST('/admin/nodes/{id}/clear')
  @Headers(ACCEPT_JSON)
  async clearAdminNodeDetail(@Path('id') id: string | number): Promise<Response<GenericResponse<AdminNodeDetail>>> {
    return {} as Response;
  }

}
